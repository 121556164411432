import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";
import { Element } from "react-scroll";
import { caseStudyData } from "../../data/caseStudyData";

const CaseStudySectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const CaseStudySectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const CaseStudySectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const CaseStudyMansoryGridWrapper = styled.div`
  padding: 60px 0 30px;
`;

const CaseStudyCmpWrapper = styled.div`
  cursor: pointer;
  min-height: 309px;
  height: 100%;
  border-radius: 16px;
  /* background: url("/assets/pattern.svg"); */
  background: ${(props) => props.image && `url(${props.image}) #00000066`};
  background-blend-mode: multiply;
  background-repeat: no-repeat;
  /* width: 32%;
  flex: 0 0 32%; */
  background-size: cover;
  background-position: center;
  cursor: pointer;
  position: relative;

  /* @media only screen and (max-width: 896px) {
    width: 48%;
    flex: 0 0 48%;
  }

  @media only screen and (max-width: 779px) {
    width: 46%;
    flex: 0 0 46%;
  }

  @media only screen and (max-width: 640px) {
    width: 100%;
    flex: 0 0 100%;
  } */
`;

const CaseStudyInfoSection = styled.div`
  border-radius: 0 0 16px 16px;
  background: #000e1466;
  position: absolute;
  bottom: 0;
  height: 200px;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  gap: 0px;
  justify-content: flex-start;

  @media only screen and (max-width: 640px) {
    width: fit-content;
    height: 180px;
  }
`;

const CaseStudyTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  text-align: left;
  /* padding: 15px 0; */

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const CaseStudyDesc = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  padding-top: 10px;
  color: #fff;

  @media only screen and (max-width: 1023px) {
    font-size: 16px;
  }

  @media only screen and (max-width: 769px) {
    font-size: 12px;
    line-height: 20.4px;
  }
`;

const SectionDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 40px;
  max-width: 1280px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
  }
`;

const CaseStudySection = () => {
  const navigate = useNavigate();
  const CaseStudyCmp = ({ data }) => {
    return (
      <CaseStudyCmpWrapper
        image={data.image}
        onClick={() => {
          navigate(`/case-study/${data.slug}`);
        }}
      >
        <CaseStudyInfoSection>
          <CaseStudyTitle>{data.cardTitle}</CaseStudyTitle>
          <CaseStudyDesc>{data.cardDesc}</CaseStudyDesc>
        </CaseStudyInfoSection>
      </CaseStudyCmpWrapper>
    );
  };
  return (
    <Element name="case-studies">
      <CaseStudySectionContainer>
        <CaseStudySectionInnerContainer>
          <CaseStudySectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"SOME OF OUR WORK"}
              secondTitle={"Scaling B2B Growth with AI-Powered Systems"}
            />
            <SectionDesc>
              Within twelve months, our client saw their revenue skyrocket to
              $90K/month, thanks to the fully automated sales outreach system we
              developed.
            </SectionDesc>

            <CaseStudyMansoryGridWrapper>
              <ResponsiveMasonry columnsCountBreakPoints={{ 300: 1, 768: 2 }}>
                <Masonry gutter="30px">
                  {[
                    ...caseStudyData,
                    // {
                    //   title: "Coaching: $125K/mo to $225K/mo in three months",
                    //   desc: "Building an automated onboarding & fulfillment system in ClickUp helped this coaching company grow by over $100,000 in one quarter.",
                    //   image: "/assets/case-study-image-3.jpeg",
                    // },
                    // {
                    //   title: "Coaching: $125K/mo to $225K/mo in three months",
                    //   desc: "Building an automated onboarding & fulfillment system in ClickUp helped this coaching company grow by over $100,000 in one quarter.",
                    //   image: "/assets/case-study-image-2.jpeg",
                    // },
                  ]?.map((v, i) => {
                    return <CaseStudyCmp key={i} data={v} />;
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </CaseStudyMansoryGridWrapper>
          </CaseStudySectionContentWrapper>
        </CaseStudySectionInnerContainer>
      </CaseStudySectionContainer>
    </Element>
  );
};

export default CaseStudySection;

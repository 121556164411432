import { Button } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SideDrawerMobile from "./SideDrawerMobile";
import { useLocation, useNavigate } from "react-router-dom";
import { Link, scroller } from "react-scroll";

const HeaderSectionContainer = styled.div`
  width: 100%;
  height: 72px;
  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.navBarState ? "rgba(0, 14, 20, 0.1)" : "rgba(0, 14, 20)"};
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
`;

const HeaderSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const HeaderSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const HeaderSectionContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  cursor: pointer;
  width: 40px;
  /* width: 50px; */
`;

const DesktopMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 536px;
  justify-content: space-between;
  gap: 10px;
  width: 100%;

  @media only screen and (max-width: 906px) {
    display: none;
  }
`;

const MenuItem = styled.h2`
  cursor: pointer;
  letter-spacing: 0.20000000298023224px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.5px;
`;

const IntroButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 906px) {
    display: none;
  }

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    padding: 13px 20px;
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    border-radius: 12px;
    height: 48px;
  }

  @media only screen and (max-width: 600px) {
    & .ant-btn-primary,
    & .ant-btn-primary:hover,
    & .ant-btn-primary:focus {
      font-size: 14px;
      font-weight: 700;
      line-height: 28px;
      height: 40px;
    }
  }
`;

const IntroButton = styled(Button)`
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: none; // Removes the default border

  @media only screen and (max-width: 600px) {
    width: 220px;
  }

  @media only screen and (max-width: 355px) {
    width: 210px;
  }
`;

const HamburgerMenuWrapper = styled.div`
  display: none;
  cursor: pointer;

  @media only screen and (max-width: 906px) {
    display: unset;
  }
`;

const MenuIcon = styled.img`
  width: 30px;
  aspect-ratio: 1;
`;

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [navbarStateScroll, setNavbarStateScroll] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const [url, setUrl] = useState("");

  const changeBackgroundColor = () => {
    if (window.scrollY >= 10) {
      setNavbarStateScroll(true);
    } else {
      setNavbarStateScroll(false);
    }
  };

  window.addEventListener("scroll", changeBackgroundColor);

  useEffect(() => {
    const getCookieValue = (name) => {
      const cookieName = `${name}=`;
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(";");
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) === 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return "";
    };

    const fbcValue = getCookieValue("_fbc");

    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org/?format=json");
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return ""; // Return empty string in case of error
      }
    };

    const fetchData = async () => {
      const ipAddress = await fetchIpAddress();
      const userAgent = navigator.userAgent;

      const capiUrl = `https://calendly.com/stas-sorokin/project-development-meeting-with-stas-sorokin-clone?utm_medium=${ipAddress}&utm_source=${userAgent}&utm_campaign=${fbcValue}`;

      setUrl(capiUrl);
    };

    fetchData();
  }, []);

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (location.pathname !== "/") {
      await navigate("/");
    }
    scrollTarget(target);
  };

  return (
    <>
      <HeaderSectionContainer navBarState={navbarStateScroll}>
        <HeaderSectionInnerContainer>
          <HeaderSectionContentWrapper>
            <HeaderSectionContentContainer>
              <div
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  cursor: "pointer",
                }}
              >
                <Logo src={"/assets/logo.png"} alt="Logo" />{" "}
                <span style={{ color: "#fff", fontSize: "16px" }}>
                  Bles Software
                </span>
              </div>

              <DesktopMenuWrapper>
                {[
                  { title: "Services", page: false, id: "services" },
                  { title: "Case Studies", page: false, id: "case-studies" },
                  { title: "How it works", page: false, id: "how-it-works" },
                  { title: "Blog", page: true, route: "/blog" },
                  { title: "Contact Us", page: true, route: "/contact" },
                ]?.map((v, i) => {
                  return v.page ? (
                    <MenuItem
                      key={i}
                      onClick={() => {
                        navigate(v.route);
                      }}
                    >
                      {v.title}
                    </MenuItem>
                  ) : (
                    <Link
                      onClick={() => scrollToPage(v.id)}
                      key={i}
                      to={v.id}
                      spy={true}
                      smooth={true}
                      offset={0}
                      duration={1000}
                    >
                      <MenuItem key={i}>{v.title}</MenuItem>
                    </Link>
                  );
                })}
              </DesktopMenuWrapper>

              <IntroButtonContainer>
                <IntroButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  onClick={() => {
                    window.open(url, "_blank");
                  }}
                >
                  Schedule a call with us →
                </IntroButton>
              </IntroButtonContainer>

              <HamburgerMenuWrapper
                onClick={() => {
                  setOpenMobileMenu(true);
                }}
              >
                <MenuIcon src={"/assets/menu.svg"} alt="Menu" />
              </HamburgerMenuWrapper>
            </HeaderSectionContentContainer>
          </HeaderSectionContentWrapper>
        </HeaderSectionInnerContainer>
      </HeaderSectionContainer>
      <SideDrawerMobile
        isOpen={openMobileMenu}
        onClose={() => {
          setOpenMobileMenu(false);
        }}
      />
    </>
  );
};

export default Header;

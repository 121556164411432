import React from "react";
import styled from "styled-components";

const CaseStudyHeaderContainer = styled.div`
  width: 100%;
  background-size: cover !important;
  /* background: url("/assets/case-study-header.jpeg") rgba(0, 0, 0, 0.7); */
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) rgba(0, 0, 0, 0.7)`
      : 'url("/assets/case-study-header.jpeg") rgba(0, 0, 0, 0.7)'};
  background-repeat: no-repeat;
  background-blend-mode: multiply;
`;

const CaseStudyInnerHeaderContainer = styled.div`
  padding: 60px 10px;
`;

const CaseStudyInnerHeaderContent = styled.div`
  max-width: 1164px;
  width: 100%;
  margin: 0 auto;
`;

const HeaderText = styled.h2`
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 1.2000000476837158px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 1.2000000476837158px;
  }
`;

const SmallText = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  padding-top: 10px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    padding-top: 30px;
  }
`;

const CaseStudyHeader = ({ currentCaseStudy }) => {
  return (
    <CaseStudyHeaderContainer bgImage={currentCaseStudy?.image}>
      <CaseStudyInnerHeaderContainer>
        <CaseStudyInnerHeaderContent>
          <HeaderText>
            {/* Coaching: $125K/mo to $225K/mo in{" "}
            <CustomUnderline>three</CustomUnderline> months */}
            {currentCaseStudy?.cardTitle || ""}
          </HeaderText>
          <SmallText>{currentCaseStudy?.cardDesc || ""}</SmallText>
        </CaseStudyInnerHeaderContent>
      </CaseStudyInnerHeaderContainer>
    </CaseStudyHeaderContainer>
  );
};

export default CaseStudyHeader;

import React from "react";
import styled from "styled-components";
import CustomDoubleTitle from "../General/CustomDoubleTitle";

const OperationSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
`;

const OperationSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const OperationSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const SectionDesc = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 40px;
  max-width: 1280px;
  width: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
  }
`;

const FeatureContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    gap: 50px;
  }
`;

const FeatureCardWrapper = styled.div`
  flex: 0 0 30%;
  width: 30%;
  display: flex;
  flex-direction: column;
  gap: 30px;

  @media only screen and (max-width: 1090px) {
    flex: 0 0 32%;
    width: 32%;
  }

  @media only screen and (max-width: 700px) {
    flex: 0 0 100%;
    width: 100%;
  }
`;

const FeatureNumber = styled.div`
  width: 40px;
  height: 22px;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #20343d;
  color: #0fd4fb;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  border-radius: 16px;
`;

const FeatureTitle = styled.h2`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.94px;
  letter-spacing: 0.9599999785423279px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-weight: 600;
  }
`;

const FeatureDesc = styled.p`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;
  }
`;

const OperationSection = () => {
  const FeatureCard = ({ data }) => {
    return (
      <>
        <FeatureCardWrapper>
          <FeatureNumber>{data.key}</FeatureNumber>
          <FeatureTitle>{data.title}</FeatureTitle>
          <FeatureDesc>{data.desc}</FeatureDesc>
        </FeatureCardWrapper>
      </>
    );
  };
  return (
    <>
      <OperationSectionContainer>
        <OperationSectionInnerContainer>
          <OperationSectionContentWrapper>
            <CustomDoubleTitle
              firstTitle={"OPERATIONS AS A SERVICE"}
              secondTitle={"Expert-Level Team on a Flexible Plan"}
            />
            <SectionDesc>
              Agencies are slow, expensive, and demand large commitments
              upfront. Hiring in-house comes with similar challenges. We offer a
              different solution: an expert-level team of project managers,
              designers, and developers on a flexible plan, giving you the
              skills and support you need for less.
            </SectionDesc>

            <FeatureContainer>
              {[
                {
                  key: 1,
                  title: "Pause anytime",
                  desc: "The best systems are built in short bursts, not long cycles. Don't pay for a 4 mo+ agency retainer—get what you need with us & pause or cancel your subscription anytime.",
                },
                {
                  key: 2,
                  title: "No bloat",
                  desc: "We send you async project updates in Slack, and offer weekly ops consultations for discussions or brainstorming. We'll also revise systems until you're 100% satisfied.",
                },
                {
                  key: 3,
                  title: "Results twice as fast",
                  desc: "We can start as early as tomorrow. You'll hop on a thirty minute onboarding call to share credentials and get set up on our PM system, and then we'll get to work.",
                },
              ]?.map((v, i) => {
                return <FeatureCard key={i} data={v} />;
              })}
            </FeatureContainer>
          </OperationSectionContentWrapper>
        </OperationSectionInnerContainer>
      </OperationSectionContainer>
    </>
  );
};

export default OperationSection;

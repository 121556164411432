import React from "react";
import styled from "styled-components";
import Drawer from "react-modern-drawer";
import { Link, scroller } from "react-scroll";
import "react-modern-drawer/dist/index.css";
import { useLocation, useNavigate } from "react-router-dom";

const Container = styled.div`
  padding: 3rem 2rem;
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const MenuItem = styled.h2`
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  letter-spacing: 0.20000000298023224px;
  color: #fff;
`;

const SideDrawerMobile = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const scrollTarget = (target) =>
    scroller.scrollTo(target, { smooth: true, duration: 700 });

  const scrollToPage = async (target) => {
    if (location.pathname !== "/") {
      await navigate("/");
    }
    scrollTarget(target);
  };

  return (
    <Drawer
      open={isOpen}
      onClose={onClose}
      direction="left"
      size={"250px"}
      zIndex={10010000}
      style={{ background: "#000e14" }}
    >
      <Container>
        <MenuWrapper>
          {[
            { title: "Services", page: false, id: "services" },
            { title: "Case Studies", page: false, id: "case-studies" },
            { title: "How it works", page: false, id: "how-it-works" },
            { title: "Blog", page: true, route: "/blog" },
            { title: "Contact Us", page: true, route: "/contact" },
          ]?.map((v, i) => {
            return v.page ? (
              <MenuItem
                key={i}
                onClick={() => {
                  navigate(v.route);
                }}
              >
                {v.title}
              </MenuItem>
            ) : (
              <Link
                onClick={() => scrollToPage(v.id)}
                key={i}
                to={v.id}
                spy={true}
                smooth={true}
                offset={0}
                duration={1000}
              >
                <MenuItem key={i}>{v.title}</MenuItem>
              </Link>
            );
          })}
        </MenuWrapper>
      </Container>
    </Drawer>
  );
};

export default SideDrawerMobile;

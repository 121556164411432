import React, { useEffect, useState } from "react";
import Header from "../../components/General/Header";
import Footer from "../../components/General/Footer";
import Cta from "../../components/General/Cta";
import CaseStudyHeader from "../../components/CaseStudy/CaseStudyHeader";
import CaseStudyDetail from "../../components/CaseStudy/CaseStudyDetail";
import Approach from "../../components/CaseStudy/Approach";
import { useNavigate, useParams } from "react-router-dom";
import { caseStudyData } from "../../data/caseStudyData";

const CaseStudy = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [currentCaseStudy, setCurrentCaseStudy] = useState({});

  useEffect(() => {
    if (!params.slug) {
      navigate("/");
    } else {
      const filterCaseStudy = caseStudyData.filter(
        (value) => value?.slug === params.slug
      );

      if (filterCaseStudy && filterCaseStudy.length > 0) {
        setCurrentCaseStudy(filterCaseStudy[0]);
      }
    }
  }, [navigate, params.slug]);

  return (
    <>
      <Header />
      <CaseStudyHeader currentCaseStudy={currentCaseStudy} />
      <Approach currentCaseStudy={currentCaseStudy} />
      <CaseStudyDetail currentCaseStudy={currentCaseStudy} />
      <Cta
        bigTitle={currentCaseStudy?.ctaData?.title || ""}
        smallTitle={currentCaseStudy?.ctaData?.desc || ""}
        buttonText={currentCaseStudy?.ctaData?.btnText || "Scale my Company →"}
      />
      <Footer />
    </>
  );
};

export default CaseStudy;

import React, { useState } from "react";
import styled from "styled-components";
import CustomUnderline from "../General/CustomUnderline";
import { Form, Row, Col, Input, Button, notification } from "antd";
import axios from "axios";

const ContactSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0 80px;
  min-height: 90vh;
`;

const ContactSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const ContactSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const ContactInnerContentTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 943px;
  width: 100%;
  margin: 0 auto;
`;

const BigTitle = styled.h2`
  color: #fff;
  font-size: 48px;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: 1.2000000476837158px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 24px;
    line-height: 36px;
  }
`;

const PageDesc = styled.p`
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  padding-top: 20px;
  text-align: center;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

const ContactAreaContainer = styled.div`
  max-width: 1274px;
  width: 100%;
  margin: 50px auto;
  border-radius: 15px;
  background: linear-gradient(
    135.37deg,
    rgba(9, 126, 149, 0.1) 3.85%,
    rgba(15, 212, 251, 0.1) 57.47%,
    rgba(9, 126, 149, 0.1) 92.73%
  );
`;

const ContentInnerContainer = styled.div`
  padding: 30px;

  @media only screen and (max-width: 600px) {
    padding: 30px 15px;
  }
`;

const ResponsiveRow = styled(Row)`
  @media (max-width: 768px) {
    & > .ant-col {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  &.ant-form-item {
    padding-bottom: 0 !important;
  }

  .ant-input {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
  }

  .ant-input::placeholder {
    color: #fff;
    opacity: 60%;
    font-weight: 300;
    font-size: 14px;
  }

  .ant-form-item-label {
    text-align: left;
  }
  .ant-form-item-label label {
    color: #fff;
  }
  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-group {
    display: inline;
    margin-bottom: 10px;
  }

  .ant-checkbox-wrapper span {
    color: #fff;
  }

  @media (max-width: 768px) {
    .ant-row.ant-form-item-row {
      flex-direction: column;
    }
  }
`;

const GradientButton = styled(Button)`
  border-radius: 8px;
  max-width: 280px;
  width: 100%;
  border: none; // Removes the default border
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px; // Adjusts the margin above the button as needed

  & .ant-btn-primary,
  & .ant-btn-primary:hover,
  & .ant-btn-primary:focus {
    background: linear-gradient(
      94.04deg,
      #0379ff 0%,
      #1e94ab 40.32%,
      #0379ff 87.36%
    ) !important;
    border: none !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
  }
`;

const ContactSection = () => {
  const inputStyle = {
    backgroundColor: "transparent", // White fill color
    borderRadius: "5px", // Border radius
    border: "1px solid #82828299", // Border style
  };

  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    // use formdata to make it work in react
    const data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("phone", values.phone);
    data.append("subject", values.subject);
    data.append("question", values.question);

    setLoading(true);
    try {
      const response = await axios.post(process.env.REACT_APP_FORM_URL, data, {
        headers: {
          "Content-Type": "text/plain;charset=utf-8",
        },
      });

      if (response?.status === 200) {
        form.resetFields();
        api.success({
          message: "We will be in touch soon",
          style: { zIndex: 20000 },
        });
      }
    } catch (error) {
      api.error({
        message: "An error was encountered during submission",
        style: { zIndex: 20000 },
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {contextHolder}
      <ContactSectionContainer>
        <ContactSectionInnerContainer>
          <ContactSectionContentWrapper>
            <ContactInnerContentTitleWrapper>
              <BigTitle>
                Ask us a <CustomUnderline>question</CustomUnderline>
              </BigTitle>
              <PageDesc>
                Have a miscellaneous question that's not related to getting
                started or hiring? Reach out below & a member of the team will
                get back to you within two business days.
              </PageDesc>
            </ContactInnerContentTitleWrapper>

            <ContactAreaContainer>
              <ContentInnerContainer>
                <Form
                  form={form}
                  onFinish={onFinish}
                  layout="vertical"
                  size="large"
                >
                  <ResponsiveRow gutter={16}>
                    <Col xs={24} sm={12}>
                      <StyledFormItem
                        name="name"
                        label="Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input placeholder="Name" style={inputStyle} />
                      </StyledFormItem>
                    </Col>
                    <Col xs={24} sm={12}>
                      <StyledFormItem
                        name="email"
                        label="Email Address"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your email!",
                          },
                        ]}
                      >
                        <Input placeholder="Email" style={inputStyle} />
                      </StyledFormItem>
                    </Col>
                  </ResponsiveRow>
                  <ResponsiveRow gutter={16}>
                    <Col xs={24} sm={12}>
                      <StyledFormItem
                        name="phone"
                        label="Phone Number"
                        rules={[
                          {
                            required: true,
                            message: "Please input your phone number!",
                          },
                          {
                            pattern: new RegExp(/^\+?[1-9]\d{1,14}$/),
                            message: "Please enter a valid phone number!",
                          },
                        ]}
                      >
                        <Input placeholder="Phone" style={inputStyle} />
                      </StyledFormItem>
                    </Col>
                    <Col xs={24} sm={12}>
                      <StyledFormItem
                        name="subject"
                        label="Subject"
                        rules={[
                          {
                            required: true,
                            message: "Please input subject!",
                          },
                        ]}
                      >
                        <Input placeholder="Subject" style={inputStyle} />
                      </StyledFormItem>
                    </Col>
                  </ResponsiveRow>
                  <StyledFormItem
                    name="question"
                    label="Question"
                    rules={[
                      {
                        required: true,
                        message: "Please input question",
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Enter your question"
                      rows={3}
                      style={inputStyle}
                    />
                  </StyledFormItem>
                  <Form.Item>
                    <SubmitButtonContainer>
                      <GradientButton
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                      >
                        Submit
                      </GradientButton>
                    </SubmitButtonContainer>
                  </Form.Item>
                </Form>
              </ContentInnerContainer>
            </ContactAreaContainer>
          </ContactSectionContentWrapper>
        </ContactSectionInnerContainer>
      </ContactSectionContainer>
    </>
  );
};

export default ContactSection;

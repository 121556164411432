import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { blogData } from "../../data/blogData";
import Header from "../../components/General/Header";
import Cta from "../../components/General/Cta";
import Footer from "../../components/General/Footer";
import parse from "html-react-parser";

const PostSectionContainer = styled.div`
  width: 100%;
  background-color: #000e14;
  padding: 40px 0;
  min-height: 90vh;
`;

const PostSectionInnerContainer = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1430px) {
    max-width: 1250px;
  }
`;

const PostSectionContentWrapper = styled.div`
  padding: 0;

  @media only screen and (max-width: 1280px) {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 0 30px;
  }

  @media only screen and (max-width: 942px) {
    padding: 0 15px;
  }
`;

const SectionDesc = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  color: #fff;
  padding: 20px 0 40px;
  max-width: 1280px;
  width: 100%;

  & > p {
    font-size: 16px;
    padding-bottom: 20px;
  }

  & > ul,
  & > ol {
    list-style-position: inside;
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 23.8px;

    & > p {
      font-size: 14px;
    }
  }
`;

const PostImgContainer = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.imageUrl && `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
  }

  > * {
    position: relative;
    z-index: 2;
  }
`;

const BlogTitle = styled.h2`
  color: #fff;
  font-size: 36px;
  font-weight: 600;
  line-height: 43.88px;
  letter-spacing: 0.9599999785423279px;
  text-align: center;
  max-width: 768px;
  margin: 0 auto;
  padding: 0 10px;

  @media only screen and (max-width: 600px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    letter-spacing: 0.9599999785423279px;
  }
`;

const PostCmp = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [currentPost, setCurrentPost] = useState({});

  useEffect(() => {
    if (!params.slug) {
      navigate("/blog");
    } else {
      const filterPost = blogData.filter(
        (value) => value?.slug === params.slug
      );

      if (filterPost && filterPost.length > 0) {
        setCurrentPost(filterPost[0]);
      }
    }
  }, [currentPost, navigate, params]);
  return (
    <>
      <Header />
      <PostImgContainer imageUrl={currentPost?.image}>
        <BlogTitle>{currentPost?.title || ""}</BlogTitle>
      </PostImgContainer>
      <PostSectionContainer>
        <PostSectionInnerContainer>
          <PostSectionContentWrapper>
            {currentPost && (
              <SectionDesc>{parse(currentPost?.content || "")}</SectionDesc>
            )}
          </PostSectionContentWrapper>
        </PostSectionInnerContainer>
      </PostSectionContainer>
      <Cta
        bigTitle={"How Bles Software Can Help:"}
        smallTitle={
          "At Bles Software, we specialize in integrating AI into your existing systems, ensuring a smooth transition and maximum impact. Our expert team designs custom AI solutions tailored to your business needs, helping you unlock new levels of efficiency and growth."
        }
        buttonText={"Schedule a call with us →"}
      />
      <Footer />
    </>
  );
};

export default PostCmp;
